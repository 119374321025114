<template>
  <div>
    <c-modal
      :is-open="showModal"
      :on-close="closeUserModal"
      is-centered
      size="lg"
      style="border-radius: 10px"
    >
      <c-modal-content ref="content">
        <c-modal-header>Users </c-modal-header>
        <c-modal-close-button @click="closeUserModal()" />
        <c-modal-body>
          <div style="height: 450px; overflow: scroll">
            <div class="mb-3 static">
              <div class="search_input">
                <c-input-group>
                  <c-input-left-element>
                    <i class="fas fa-search"></i>
                  </c-input-left-element>
                  <c-input
                    :value="query"
                    @input.native="handleInput"
                    placeholder="Search people..."
                    borderWidth="0"
                    borderRadius="20"
                    size="lg"
                    variant="filled"
                    type="text"
                  />
                  <c-button
                    @click.native="closeUserModal"
                    style="background: rgba(255, 69, 0, 0.45); color: white"
                    >Done</c-button
                  >
                </c-input-group>
              </div>
              <div style="margin-top: 10px" v-if="selectedTaggedUsers.length">
                <c-flex
                  wrap="wrap"
                  style="border: 2px solid #c2c1c1"
                  borderRadius="5px"
                  p="5px"
                  w="100%"
                >
                  <div
                    style="
                      background: rgba(255, 77, 0, 0.36);
                      display: flex;
                      color: orangered;
                      margin: 7px;
                      padding: 5px;
                      border-radius: 5px;
                    "
                    :key="person.id"
                    v-for="person in selectedTaggedUsers"
                  >
                    <c-text
                      >{{ person.firstname }} {{ person.lastname }}</c-text
                    >
                    <c-icon-button
                      ml="5px"
                      aria-label="Remove tagged user"
                      variant="ghost"
                      variant-color="orange"
                      size="xs"
                      icon="close"
                      @click="removeTaggedUser(person)"
                    />
                  </div>
                </c-flex>
              </div>
            </div>
            <c-box class="modal-contents">
              <div style="text-align: center" v-if="loading">
                <c-spinner ml="2" variant-color="orange" thickness="2px" />
              </div>
              <div v-else>
                <c-box
                  @click="selected(item)"
                  v-for="item in allUsers"
                  :key="item.id"
                >
                  <tagged-person-card :person="item" />
                </c-box>
              </div>
            </c-box>
          </div>
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import { getUsers } from '@/services/user.js';
import TaggedPersonCard from '@/views/App/Explore/components/tagged/TaggedPersonCard.vue';
import debounce from 'lodash.debounce';
import { getMatchingUsers } from '@/services/insight';
import { mapGetters } from 'vuex';
import { EventBus } from '@/eventBus';

export default {
  name: 'TaggedUsersModal',
  props: {
    showUsersModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TaggedPersonCard,
  },

  data() {
    return {
      loading: true,
      showModal: true,
      query: '',
      allUsers: [],
    };
  },
  created() {
    this.getUsers();
  },
  computed: {
    ...mapGetters({
      selectedTaggedUsers: 'explore/getSelectedTaggedUsers',
    }),
  },
  methods: {
    removeTaggedUser(person) {
      if (this.selectedTaggedUsers.includes(person)) {
        this.$store.dispatch('explore/removeTaggedUser', person);
        this.allUsers.splice(0, 0, person);
      }
    },
    selected(person) {
      if (!this.selectedTaggedUsers.includes(person)) {
        this.$store.dispatch('explore/selectTaggedUser', person);
        this.allUsers = this.allUsers.filter((usr) => usr.id !== person.id);
        console.log('already selected');
      }
    },
    search: debounce(async function (searchQuery) {
      if (!searchQuery) {
        await this.getUsers();
        return;
      }

      // Replace with your actual search logic/API call
      await this.getUsersMatchingQuery();
    }, 400), // 300ms debounce delay
    async getUsersMatchingQuery() {
      const response = await getMatchingUsers('%' + this.query + '%');

      const users = response.data.user;
      //  get only usrs who have firstname and lastname and companies array has data
      // limit to 5 users
      this.allUsers = users.filter(
        (user) => user.firstname && user.lastname && user.companies.length > 0
      );
      this.loading = false;
    },
    handleInput(event) {
      if (event.target) {
        this.query = event.target.value;
        this.search(this.query);
      }
    },
    async getUsers() {
      try {
        const response = await getUsers(30);

        const users = response.data.user;

        this.allUsers = users.filter(
          (user) => user.firstname && user.lastname && user.companies.length > 0
        );

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    closeUserModal() {
      this.showModal = false;
      this.$emit('closeAllUsersModal');
      EventBus.$emit('open-post-modal');
    },
  },
};
</script>
<style lang="scss">
.modal-contents {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
