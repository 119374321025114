<template>
  <div>
    <c-modal
      :is-open="showModal"
      :on-close="closeCompanyModal"
      is-centered
      size="lg"
      borderRadius="xs"
    >
      <c-modal-content ref="content">
        <c-modal-header>Companies </c-modal-header>
        <c-modal-close-button @click="closeCompanyModal" />
        <c-modal-body>
          <div style="height: 450px; overflow: scroll">
            <div class="mb-3" style="position: static">
              <div class="search_input">
                <c-input-group>
                  <c-input-left-element>
                    <i class="fas fa-search"></i>
                  </c-input-left-element>
                  <c-input
                      :value="query"
                      @input.native="handleInput"
                      placeholder="Search people..."
                      borderWidth="0"
                      borderRadius="20"
                      size="lg"
                      variant="filled"
                      type="text"
                  />
                  <c-button @click.native="closeCompanyModal" style="background: rgba(255,69,0,0.45); color: white">Done</c-button>
                </c-input-group>
              </div>
              <div style="margin-top: 10px" v-if="selectedTaggedCompanies.length">
                <c-flex
                    wrap="wrap"
                    style="border: 2px solid #c2c1c1"
                    borderRadius="5px"
                    p="5px"
                    w="100%"
                >
                  <div
                      style="
                  background: rgba(255, 77, 0, 0.36);
                  display: flex;
                  color: orangered;
                  margin: 7px;
                  padding: 5px;
                  border-radius: 5px;
                "
                      :key="company.id"
                      v-for="company in selectedTaggedCompanies"
                  >
                    <c-text>{{ company.name }}</c-text>
                    <c-icon-button
                        ml="5px"
                        aria-label="Remove tagged user"
                        variant="ghost"
                        variant-color="orange"
                        size="xs"
                        icon="close"
                        @click="removeTaggedCompany(company)"
                    />
                  </div>
                </c-flex>
              </div>
            </div>
            <c-box class="modal-contents">
              <div style="text-align: center" v-if="loading">
                <c-spinner ml="2" variant-color="orange" thickness="2px" />
              </div>
              <div v-else>
                <c-box
                    @click="selected(item)"
                    v-for="item in allCompanies"
                    :key="item.id"
                >
                  <tagged-company-card :company="item" />
                </c-box>
              </div>
            </c-box>
          </div>
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import TaggedCompanyCard from '@/views/App/Explore/components/tagged/TaggedCompanyCard.vue';
import debounce from 'lodash.debounce';
import uniq from 'lodash.uniq'
import { mapGetters } from 'vuex';
import { EventBus } from '@/eventBus';
import { getCompanies, getMatchingCompanies } from '@/services/company';

export default {
  name: 'TaggedCompaniesModal',
  props: {
    showUsersModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TaggedCompanyCard,
  },

  data() {
    return {
      loading: true,
      showModal: true,
      query: '',
      allCompanies: [],
    };
  },
  created() {
    this.getCompanies();
  },
  computed: {
    ...mapGetters({
      selectedTaggedCompanies: 'explore/getSelectedTaggedCompanies',
    }),
  },
  methods: {
    removeTaggedCompany(company) {
      if (this.selectedTaggedCompanies.includes(company)) {
        this.$store.dispatch('explore/removeTaggedCompany', company);
        this.allCompanies.splice(0, 0, company);
      }
    },
    selected(company) {
      console.log('Selected company xxx ==>', company);
      if (!this.selectedTaggedCompanies.includes(company)) {
        this.$store.dispatch('explore/selectTaggedCompany', company);
        this.allCompanies = this.allCompanies.filter((cmp) => cmp.id !== company.id)
      } else {
        console.log('already selected');
      }
    },
    search: debounce(async function (searchQuery) {
      if (!searchQuery) {
        await this.getCompanies();
        return;
      }

      // Replace with your actual search logic/API call
      await this.getCompaniesMatchingQuery(searchQuery);
    }, 400), // 300ms debounce delay
    async getCompaniesMatchingQuery() {
      const response = await getMatchingCompanies('%'+this.query+'%');

      const companies = response.data.company;

      console.log('Returned here==>', companies);
      //  get only usrs who have firstname and lastname and companies array has data
      // limit to 5 users
      this.allCompanies = companies;
      this.loading = false;
    },
    handleInput(event) {
      if (event.target) {
        this.query = event.target.value;
        this.search(this.query);
      }
    },
    async getCompanies() {
      try {
        const response = await getCompanies(15);

        //  get only usrs who have firstname and lastname and companies array has data
        // limit to 5 users
        this.allCompanies = response.data.company;
        this.allCompanies = uniq(this.allCompanies.concat(this.selectedTaggedCompanies))
        console.log('aall companiezz', this.allCompanies);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    closeCompanyModal() {
      this.showModal = false;
      this.$emit('closeAllCompaniesModal');
      EventBus.$emit('open-post-modal');
    },
  },
};
</script>

<style lang="scss">
.modal-contents {
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
