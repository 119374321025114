var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select",class:{ 'select--w-full': _vm.fullWidth }},[_c('v-select',_vm._g(_vm._b({staticClass:"vc-select",class:[
      `vc-select--${_vm.indicatorFill}-indicator`,
      `vc-select--${_vm.color}`,
      `vc-select--${_vm.size}`,
      `vc-select--border-${_vm.borderColor}`,
      {
        'vc-select--bordered': _vm.bordered,
        'vc-select--shadow': _vm.hasShadow,
        'vc-select--default': _vm.reset,
        'vc-select--w-full': _vm.fullWidth,
        'vc-select--has-placeholder': _vm.placeholder,
        'vc-select--has-value': _vm.currentValue,
      },
    ],attrs:{"clearable":false,"searchable":_vm.searchable,"value":_vm.currentValue,"options":_vm.options,"placeholder":_vm.placeholder || 'select',"appendToBody":_vm.appendToBody,"disabled":_vm.disabled,"multiple":_vm.multiple,"label":_vm.label,"reduce":_vm.reduce},on:{"open":_vm.onSelectOpen},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._t("option",null,{"option":option})]}},{key:"selected-option",fn:function(option){return [_vm._t("selected-option",null,{"option":option})]}},{key:"no-options",fn:function(noOptions){return [_vm._t("no-options",null,{"option":noOptions})]}},{key:"list-footer",fn:function(){return [_vm._t("list-footer")]},proxy:true}],null,true)},'v-select',_vm.$attrs,false),{
      ..._vm.$listeners,
      input: _vm.onSelected,
    }))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }