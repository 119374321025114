<template>
  <c-flex my="4" justify="space-between" align="center">
    <c-flex align="center">
      <img
          v-if="company.logo"
          alt="user-avatar"
          class="user-avatar"
          :src="company.logo"
      />
      <img v-else alt="user-avatar" width="45px" src="@/assets/img/company.png" />
      <c-box
          ml="4"
          color="gray.700"
          style="cursor: pointer"
      >
        <c-text flex="column" fontSize="14px" fontWeight="700"
        >{{ company.name }}</c-text
        >

<!--        <c-text fontSize="13px" fontWeight="400">{{-->
<!--            person.companies[0].name-->
<!--          }}</c-text>-->
      </c-box>
    </c-flex>

    <!--    <c-flex justify="end">-->
    <!--      <c-button-->
    <!--          variant-color="vc-orange"-->
    <!--          variant="outline"-->
    <!--          size="sm"-->
    <!--          border="1px"-->
    <!--          :is-loading="btnLoading"-->
    <!--          @click="follow"-->
    <!--      >-->
    <!--        {{ followed ? 'Following' : 'Follow' }}-->
    <!--      </c-button>-->
    <!--    </c-flex>-->
  </c-flex>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'TaggedCompanyCard',
  props: ['company'],
  computed: {
    ...mapGetters({
      selectedTaggedUsers: 'explore/getSelectedTaggedUsers',
    }),
  },
  data() {
    return {
      btnLoading: false,
    };
  },
  methods: {

  },
};
</script>

<style scoped>
button:hover {
  background-color: #e5724e;
  color: #fff;
}
.user-avatar {
  border-radius: 10%;
  height: 45px;
  width: 45px;
}
</style>
