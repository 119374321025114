import { render, staticRenderFns } from "./TaggedUsersModal.vue?vue&type=template&id=25ee393d"
import script from "./TaggedUsersModal.vue?vue&type=script&lang=js"
export * from "./TaggedUsersModal.vue?vue&type=script&lang=js"
import style0 from "./TaggedUsersModal.vue?vue&type=style&index=0&id=25ee393d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CInputLeftElement: require('@chakra-ui/vue').CInputLeftElement, CInput: require('@chakra-ui/vue').CInput, CButton: require('@chakra-ui/vue').CButton, CInputGroup: require('@chakra-ui/vue').CInputGroup, CText: require('@chakra-ui/vue').CText, CIconButton: require('@chakra-ui/vue').CIconButton, CFlex: require('@chakra-ui/vue').CFlex, CSpinner: require('@chakra-ui/vue').CSpinner, CBox: require('@chakra-ui/vue').CBox, CModalBody: require('@chakra-ui/vue').CModalBody, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
