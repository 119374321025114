export const PostAddonType = {
    media: 'media',
    event: 'event',
    feeling: 'feeling',
    company: 'company',
    document: 'document',
    user: 'user'
}

export const PostScope = {
    public: 'PUBLIC',
    private: 'PRIVATE',
    company: 'COMPANY'
}

export const PostType = {
    VIRTUAL: "VIRTUAL",
    PHYSICAL: "PHYSICAL"
}